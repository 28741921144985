.rulesoutercontainer {
  font-family: Arial, Helvetica, sans-serif;
  /* background: transparent radial-gradient(closest-side at 50% 50%, #619ECF 0%, #4C80AC 8%, #366084 18%, #234464 29%, #152F4B 40%, #0B2039 52%, #04172E 64%, #03152B 78%, #020F26 88%, #00001B 100%) 0% 0% no-repeat padding-box; */
  background: #03142a 0% 0% no-repeat padding-box;
  color: white;
  line-height: 1.6;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  width: 75vw;
  margin-left: 25vw;
}

.rulespage {
  background: #03142a 0% 0% no-repeat padding-box;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 2vh;
  display: block;
  overflow: auto;
  /* height: fit-content; */
  min-height: 100vh;
}

.rulespage::-webkit-scrollbar {
  display: none;
}

.rulespage {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.rulescontainer {
  margin-left: 5vw !important;
  margin-right: 5vw !important;
  color: var(--unnamed-color-d9bd5a);
  text-align: left;
  align-content: center;
  font: normal normal normal 17px/32px Roboto;
  letter-spacing: 0px;
  color: #d9bd5a;
  opacity: 1;
}

#showcase {
  height: 10vh;
}

#showcase h1 {
  color: var(--unnamed-color-d9bd5a);
  text-align: left;
  font: normal normal bold 32px/43px Roboto Slab;
  letter-spacing: 0px;
  color: #d9bd5a;
  opacity: 1;
  position: relative;
  animation: heading;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  margin-left: 5vw !important;
}

@keyframes heading {
  0% {
    top: -50px;
    opacity: 0;
  }
  100% {
    top: 5vh;
    opacity: 1;
  }
}

#content {
  position: relative;
  animation-name: content;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes content {
  0% {
    bottom: -100vh;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}

#rulesImg {
  width: 12.42vw;
  height: 12.42vw;
  min-width: 100px;
  min-height: 100px;
  margin-top: 2vh;
  margin-left: 5vw;
  animation-name: rulesImg;
  animation-duration: 7s;
  animation-fill-mode: forwards;
}

@keyframes rulesImg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rulescloseBtn {
  position: absolute;
  top: 1.5vh;
  /* left: 72.2vw !important; */
  right: 1.5vw;
  animation-name: closeBtn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  transition-property: transform;
  transition-duration: 1s;
}

.rulescloseBtn:hover {
  transform: rotateY(180deg);
  cursor: pointer;
}

@keyframes closeBtn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.backBtn {
  height: 4vh;
  width: 4vh;
}

.rulespage {
  /* display: flex;
  justify-content: space-between; */
  max-width: 100vw;
}

.gameslist {
  background-color: black;
  width: 25vw;
  height: 100vh;
  color: blanchedalmond;
  overflow: auto;
  padding-left: -5vw !important;
}

.selectedGameName {
  font: normal normal bold 24px/36px Roboto Slab;
  background-color: black !important;
  background-repeat: no-repeat;
  border-color: goldenrod;
  color: goldenrod;
  border: 1px solid #d9bd5a;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  margin-top: 0.5vh;
  margin-bottom: 1vh;
  margin-left: 1vw;
  margin-right: 1vw;
}

.gamescontainer {
  margin-top: 5vh;
  /* text-align: center; */
}

.cardsrow {
  display: flex;
  justify-content: center;
}

.selectgamerules {
  color: black;
}
