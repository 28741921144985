.roomHeader {
  height: 56px;
  background: #050402;
  color: white;
  flex-grow: 1;
  font-size: 14px;
  min-width: max(80% + 200px, 1340px);
}

.gameSelection {
  height: 24px;
  display: flex;
  align-items: center;
  color: black;
  z-index: 12;
  font-size: 10px;
}

.outerContainer {
  display: flex;
  height: calc(100vh - 56px - 106px);
  min-height: max(78%, 490px);
  background-color: white;
}

.css-2b097c-container {
  width: 150px;
  /*width: 100%;*/
}

.chatBtn {
  position: absolute;
  right: 0;
  bottom: 106px;
}

.chatContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-width: 5px;
  border-color: black;
  height: 100%;
  min-width: 200px;
}

.chatIcons:hover {
  cursor: pointer;
}

.gameContainer {
  /* min-width: max(80%, 1140px); */
  background: transparent
    radial-gradient(closest-side at 50% 50%, #055021 0%, #032b13 100%) 0% 0%
    no-repeat padding-box;
  animation-name: containergame;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes containergame {
  0% {
    min-width: max(100%, 1140px);
  }
  100% {
    min-width: max(80%, 1140px);
  }
}

.newgameContainer {
  /* min-width: max(100%, 1140px); */
  background: transparent
    radial-gradient(closest-side at 50% 50%, #055021 0%, #032b13 100%) 0% 0%
    no-repeat padding-box;
  animation-name: containernewgame;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes containernewgame {
  0% {
    min-width: max(80%, 1140px);
  }
  100% {
    min-width: max(100%, 1140px);
  }
}

.innerspectator {
  outline: 2px solid black;
  flex: 1;
  overflow-y: scroll;
}

.chatMessages {
  flex: 1;
  overflow-y: scroll;
}

.GameBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  min-width: 108px;
  height: 30px;
  color: #050402;
  border-color: white;
  font-size: 14px;
  font-weight: bold;
  margin: 0 8px;
}

.removeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 87, 45);
  color: #050402;
  border-color: red;
  font-size: 12px;
  color: white;
  font-weight: bold;
}

.removeBtn:hover {
  background-color: red;
}

.soundOnBtn {
  background-image: url("../../assets/Sound_On.svg");
  height: 30px !important;
  width: 30px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.soundOnBtn:hover {
  background-image: url("../../assets/Sound_On_Hover.svg");
}

.soundOffBtn {
  background-image: url("../../assets/Sound_Off.svg");
  height: 30px !important;
  width: 30px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.soundOffBtn:hover {
  background-image: url("../../assets/Sound_Off_Hover.svg");
}

.feedbackBtn {
  background-image: url("../../assets/Feedback.svg");
  height: 30px !important;
  width: 30px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.feedbackBtn:hover {
  background-image: url("../../assets/Feedback_Hover.svg");
}

.hideChatBtn {
  background-image: url("../../assets/Chat_Hide.svg");
  height: 30px !important;
  width: 30px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hideChatBtn:hover {
  background-image: url("../../assets/Chat_Hide_Hover.svg");
}

.showChatBtn {
  background-image: url("../../assets/Chat_Show.svg");
  height: 30px !important;
  width: 30px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.showChatBtn:hover {
  background-image: url("../../assets/Chat_Show_Hover.svg");
}

.kickBtn {
  min-width: 108px;
  height: 30px;
  background: #050402 0% 0% no-repeat padding-box;
  border: 1px solid #d9bd5a;
  border-radius: 5px;
  opacity: 1;
  font: normal normal bold;
  letter-spacing: 0px;
  color: #d9bd5a;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 4px !important;
  margin-right: 10px;
}

.kickBtn:hover {
  padding-top: 2px !important;
  cursor: pointer;
  border: 3px solid #d9bd5a;
  background: #050402 0% 0% no-repeat padding-box;
  color: #d9bd5a;
}

.SubmitBtn {
  min-width: 108px;
  height: 30px;
  background: #050402 0% 0% no-repeat padding-box;
  border: 1px solid #d9bd5a;
  border-radius: 5px;
  opacity: 1;
  font: normal normal bold;
  letter-spacing: 0px;
  color: #d9bd5a;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 4px !important;
}

.SubmitBtn:hover {
  padding-top: 2px !important;
  cursor: pointer;
  border: 3px solid #d9bd5a;
  background: #050402 0% 0% no-repeat padding-box;
  color: #d9bd5a;
}

.Anaconda-winning {
  display: flex;
  align-items: center;
  justify-content: center;
  background: red !important;
  min-width: 108px;
  height: 30px;
  color: #050402 !important;
  border-color: white !important;
  font-size: 14px;
  font-weight: bold;
  margin: 0 8px;
}

.GameBtn:hover,
.GameBtn:active {
  border-color: #032b13 !important;
  background: #d9bd5a !important;
  box-shadow: none !important;
  color: #050402 !important;
}

.form {
  display: flex;
  border-top: 1px solid #d3d3d3;
}

.sendMsgArea {
  min-width: 300px;
  min-height: 100px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  font-size: 14px;
}

.roomFooter {
  height: 106px;
  background: #050402;
  min-width: max(80% + 200px, 1340px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rangeslider-horizontal {
  height: 6px;
}

.potdetails {
  bottom: -40px;
  left: 0px;
}

.voteoutSomeone {
  bottom: -50px;
  right: 0px;
}

.countdown-timer {
  color: white;
}

#tableone table {
  width: 100%;
}

/* #tableone  th, td {
    padding: 8px;
    width: 33%;
    color: black;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }

#tabletwo table {
    width: 100%;
  }

#tabletwo  th, td {
    padding: 8px;
    width: 25%;
    color: black;
    text-align: center;
    border-bottom: 1px solid #ddd;
  } */

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}

.detailstable {
  color: darkblue;
  font-size: medium;
}

.myslider {
  max-width: 80%;
  width: 100%;
  min-width: 180px;
}

.sendRaiseArea {
  height: 30px;
  width: 80px;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 0px;
  margin-right: 8px;
}

.textboxstartgame {
  height: 30px;
  width: 80px;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 0px;
  margin-right: 8px;
}

.textboxchooseamount {
  height: 30px;
  width: 80px;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 10px;
}

.showList-number {
  color: white;
  margin-right: 60px;
}

.showhomerunindex {
  color: white;
  /* padding-left: 28.25px; */
  /* padding-right: 28.25px; */
  margin-right: 39px;
}

.alldetailshomerun {
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
}

.copytext {
  color: indigo;
}

.copytext :hover {
  color: red;
  cursor: pointer;
}

.cloneImg {
  position: relative;
  top: 5px;
  left: 0px;
  font-size: 20px;
  z-index: 1;
  color: black;
}

.feedback {
  z-index: 15;
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
}

.feedbackwrapper {
  position: absolute;
  margin: 0 !important;
  padding: 5px !important;
  min-width: 350px !important;
  border: none !important;
  border-radius: 5px;
  background: #10101b !important;
}

.feedbackcentered {
  position: absolute;
  left: 0;
  right: 0;
  margin: 1rem auto;
}

.feedbackside {
  height: 12rem;
  background-color: #fcfcf8;
  outline: 1px solid transparent;
  /* &.side:nth-of-type(1) {
        padding: 2rem 2rem 0;
        border-radius: 1rem 1rem 0 0;
        box-shadow: inset 0 0.75rem 2rem rgba(229, 225, 187, 0.5);
    }
    &.side:nth-of-type(2) {
        padding: 2rem;
        border-radius: 0 0 1rem 1rem;
        box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.05), inset 0 -0.57rem 2rem rgba(229, 225, 187, 0.5);
        text-align: right;
    } */
}

.cardborder {
  border: 2px solid red;
  border-radius: 4px;
  padding-left: 0px;
  margin-right: 5px;
}

.feedbackClose {
  align-items: center;
  justify-content: center;
  background: rgb(230, 193, 99);
  min-width: 100px;
  /* height: 30px; */
  color: #050402;
  border-color: white;
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}

.feedbackClose:hover {
  background: black;
  color: whitesmoke;
}

.gameTitle:hover {
  cursor: pointer;
}

.typeselection {
  position: absolute;
  z-index: 15;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: black;
  border: 1px solid gold;
  border-radius: 0.3rem;
  box-shadow: 0 2px 8px rgb(30 37 43 / 30%);
  height: fit-content;
  width: fit-content;
  padding: 5px;
  animation-name: startgameui;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.invitemenu {
  z-index: 15;
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 700px;
  animation-name: startgameui;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  border: 1px solid #707070;
  border-radius: 10px;
}

.topinvite {
  background: var(--unnamed-color-d9bd5a) 0% 0% no-repeat padding-box;
  background: #d9bd5a 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.inviteheader {
  font: normal normal bold 32px/38px Roboto;
  letter-spacing: 0px;
  margin-bottom: 10px;
}

.inviteinfo {
  font: normal normal normal 18px/20px Roboto;
  letter-spacing: 0px;
}

.inviterow {
  font: normal normal normal 16px/24px Roboto;
  margin-top: 10px;
}

.inviteitem {
  padding: 5px;
  background-color: rgb(247, 208, 111);
  border-radius: 5px;
}

.inviteBtn {
  min-width: 108px;
  height: 30px;
  background: whitesmoke 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  font: normal normal normal 14px/16px Roboto;
  letter-spacing: 0px;
  color: black;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-top: 4px !important;
  margin-right: 10px;
}

.inviteBtn:hover {
  padding-top: 5px !important;
  cursor: pointer;
  /* border: 3px solid #D9BD5A;
    border-radius: 3px; */
  background: whitesmoke 0% 0% no-repeat padding-box;
  color: black;
  font-weight: bold;
}

.bottominvite {
  background-color: white;
  margin-top: -10px;
  padding: 20px;
  border: 1px solid #707070;
  border-radius: 10px;
}

.invitelist {
  font: normal normal normal 16px/18px Roboto;
  letter-spacing: 0px;
}

.invitefriend {
  font: normal normal normal 15px/15px Roboto;
  letter-spacing: 0px;
}

.tagbutton {
  background-color: red;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  /* display: flex; */
  align-items: center;
  /* justify-content: center; */
}

.amountSelect {
  position: absolute;
  z-index: 15;
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: black;
  border: 1px solid gold;
  border-radius: 0.3rem;
  box-shadow: 0 2px 8px rgb(30 37 43 / 30%);
  height: fit-content;
  width: fit-content;
  padding: 5px;
  animation-name: startgameui;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  max-width: 600px;
}

.arrangements {
  width: 80%;
}

.arrangementstd1 {
  max-width: 10% !important;
  color: goldenrod;
}

.arrangementstr {
  color: goldenrod;
}

.cardarrangement {
  margin-top: 10px;
}

.homeRunSelect {
  position: absolute;
  z-index: 15;
  position: absolute;
  /* top: 20% !important; */
  margin-top: 3.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: black;
  border: 1px solid gold;
  border-radius: 0.3rem;
  box-shadow: 0 2px 8px rgb(30 37 43 / 30%);
  height: fit-content;
  width: fit-content;
  padding: 25px;
  font-size: 10px;
  animation-name: startgameui;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#tableone th,
td {
  padding: 8px;
  text-align: center;
}

.gameRules {
  position: absolute;
  z-index: 15;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-60%, -40%);
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: black;
  border: 1px solid gold;
  border-radius: 0.3rem;
  box-shadow: 0 2px 8px rgb(30 37 43 / 30%);
  height: fit-content;
  width: fit-content;
  padding: 5px;
  animation-name: startgameui;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.wildcardrules {
  padding: 15px !important;
  padding-top: 40px !important;
}

.feedbackSheet {
  position: absolute;
  z-index: 15;
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: whitesmoke;
  border: 1px solid transparent;
  padding: 45px !important;
  border-radius: 0.3rem;
  box-shadow: 0 2px 8px rgb(30 37 43 / 30%);
  height: fit-content;
  width: fit-content;
  padding: 5px;
  animation-name: startgameui;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.feedbackheading {
  font-weight: bold;
  font-size: x-large !important;
}

@keyframes startgameui {
  0% {
    opacity: 0;
    top: 35%;
  }
  100% {
    opacity: 1;
    top: 40%;
  }
}

.startgametext {
  text-align: center;
  font: normal normal normal Roboto;
  letter-spacing: 0px;
  color: #d9bd5a;
  opacity: 1;
}

.DefaultBtn {
  min-width: 108px;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 5px;
  border: 1px solid var(--unnamed-color-d9bd5a);
  padding-top: 4px !important;
  padding-left: 10px;
  padding-right: 10px;
  background: #050402 0% 0% no-repeat padding-box;
  border: 1px solid #d9bd5a;
  border-radius: 5px;
  opacity: 1;
  font: normal normal bold;
  letter-spacing: 0px;
  color: #d9bd5a;
}

.DefaultBtn:hover {
  padding-top: 2px !important;
  cursor: pointer;
  border: 3px solid #d9bd5a;
  background: #050402 0% 0% no-repeat padding-box;
  color: #d9bd5a;
}

.StartGameBtn {
  min-width: 108px;
  height: 30px;
  background: #050402 0% 0% no-repeat padding-box;
  border: 1px solid #d9bd5a;
  border-radius: 5px;
  opacity: 1;
  font: normal normal bold;
  letter-spacing: 0px;
  color: #d9bd5a;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 4px !important;
}

.StartGameBtn:hover {
  padding-top: 2px !important;
  cursor: pointer;
  border: 3px solid #d9bd5a;
  background: #050402 0% 0% no-repeat padding-box;
  color: #d9bd5a;
}

.bothrulesdiv:hover {
  cursor: pointer;
}

.spectator-remove {
  float: right;
  align-items: center;
  justify-content: center;
  background: red;
  font-size: 10px;
  color: white;
  font-weight: bold;
  padding: 3px;
  cursor: pointer;
}

.spectator-details {
  margin-left: 5px;
  font-weight: bold;
  font-size: 14px;
}

.spectator-row {
  padding: 2px;
  margin: 3px 0px;
  background-color: #f3f3f3;
}

.spectator-title {
  background-color: black;
  color: white;
  padding: 2px;
}

.spectatorcontainer {
  flex: 1;
  background-color: white;
  padding: 5px;
  max-height: 20% !important;
  align-content: center;
  text-align: center;
  align-items: center;
  overflow: auto;
  border-bottom: 1px solid #d3d3d3;
}

.generalrules {
  color: blue;
  text-decoration: underline;
}

.generalrules:hover {
  cursor: pointer;
}

.maincardanimation {
  animation-name: maincardanimationui;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes maincardanimationui {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.error {
  margin: 0;
  font-size: 90%;
  color: red;
}

.tag-item {
  background-color: black;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  /* padding: 0 4px 0 1rem; */
  padding: 3px;
  /* display: inline-flex; */
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  border: 2px solid goldenrod;
}

.emailinput {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}

.emailinput:focus {
  border-color: cornflowerblue;
  outline: none;
}

.emailinput.has-error {
  border-color: tomato;
}

.loading-image {
  width: 25px !important;
  height: 25px !important;
}

.arrangetext {
  font-size: 20px;
  color: goldenrod;
}

.normalcard {
  position: relative;
}

.isPassedCard {
  position: absolute;
  top: -15px;
  left: 10px;
  color: whitesmoke;
  font-size: small;
}

.isWildCard {
  position: absolute;
  bottom: -4px;
  left: 5px;
  animation-name: color_change;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes color_change {
  /* from { color: rgb(226, 198, 35); } */
  from {
    color: rgb(133, 221, 0);
  }
  to {
    color: blue;
  }
}

.wildCard {
  position: relative;
  margin-bottom: 10px;
}

.wildCardClose {
  position: absolute;
  bottom: -5px;
  left: 4px;
  color: rgb(83, 83, 83);
}

.wildCardClose:hover {
  cursor: pointer;
}

.wildCardBackBtn {
  color: #d9bd5a;
  background-color: black;
  border-color: #d9bd5a;
  position: absolute;
  left: 2px;
  top: 2px;
}

.jokersmodifyBtn {
  color: #d9bd5a;
  background-color: black;
  border-color: #d9bd5a;
  height: fit-content;
  width: 30px;
  font-weight: bolder;
}

.wildCardSelection {
  justify-content: center;
  align-items: center;
  display: flex;
}

.jokertext {
  font-weight: 1000;
}

.byranktext {
  color: #d9bd5a;
  font-size: large;
}

.byrankelement {
  border-radius: 3px;
  border-top: 1px solid rgb(211, 179, 1);
  border-left: 1px solid rgb(211, 179, 1);
  border-bottom: 1px solid #61552a;
  border-right: 1px solid #61552a;
  color: #d9bd5a;
}

.byrankbutton {
  border: none;
  background-color: transparent;
  color: #d9bd5a;
}

.rankselected {
  background-color: rgb(0, 183, 255) !important;
}

.rankbuttonselected {
  color: black !important;
}

.gameoptions-info-top-change {
  color: brown !important;
}

.gameoptions-info-top {
  color: #40871d;
  position: absolute;
  margin-left: 4%;
  border: 2px solid #a7d590;
  transform: translate(-30%, -0%);
  background-color: #dff8d3;
  min-width: 15%;
  /* max-width: 20%; */
  min-height: 5%;
  border-radius: 4px;
  padding: 10px;
  z-index: 999;
  align-items: center;
  text-align: center;
  justify-content: center;
  animation-name: fadeinout;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  font: normal normal bold 16px/25px Roboto Slab;
  text-align: center;
}

.rebuy-info-top {
  color: white;
  position: absolute;
  border: 2px solid #a7d590;
  transform: translate(-30%, -0%);
  background-color: #dff8d3;
  min-width: 15%;
  max-width: 20%;
  min-height: 5%;
  border-radius: 4px;
  padding: 10px;
  z-index: 999;
  align-items: center;
  text-align: center;
  justify-content: center;
  animation-name: fadeinout;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeinout {
  0% {
    opacity: 0;
    top: 40px;
  }
  100% {
    opacity: 1;
    top: 56px;
  }
}

.inforebuyicon {
  height: 30px;
  width: 30px;
  color: rgb(51, 179, 238);
}

.inforebuytext {
  color: #40871d;
  font: normal normal bold 15px/24px Roboto Slab;
  text-align: center;
}

.rebuyinfoBtn {
  background-color: transparent;
  border: 1px solid #a7d590;
  color: #40871d;
  font: normal normal bold 15px/24px Roboto Slab;
}

.rebuyinfoBtn:hover {
  background-color: #4a9c21;
  color: black;
  border: 1px solid #a7d590;
  font: normal normal bold 15px/24px Roboto Slab;
}

.fa-rebuyicon {
  transform: scale(1.65, 1.65);
}

.gameads {
  position: absolute;
  top: 60%;
  left: 20px;
}
