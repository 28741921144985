.ppbody {
    background: transparent !important;
    margin-left: 8px;
    padding-top: 4px;
    margin-right: 8px;
    margin-top: 8vh;
}

.pptitle {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
}

.ppsubtitle {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
}

.ppheading_1 {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
}

.ppheading_2 {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
}

.ppbody_text {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
}

.pplink {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
}

.pptable {
    width: 100%;
}

.pptabletd1 {
    width: 33.8274%;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    text-align: left;
}

.pptabletd2 {
    width: 51.4385%;
    border-top: 1px solid black;
    border-right: 1px solid black;
    text-align: left;
}

.pptabletd3 {
    width: 14.9084%;
    border-right: 1px solid black;
    border-top: 1px solid black;
    text-align: center;
}

.pplink_2 {
    cursor: pointer;
}

.ppLogo {
    height: 7vh;
    width: 9vh;
}