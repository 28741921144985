.tableContainer {
  position: relative;
  color: white;
}

/* .gameTableImage {
    height: auto;
    width: 50vw;
} */

.top-left {
  position: absolute;
  top: -32px;
  left: -70px;
}

.top-left-right-arrow {
  position: absolute;
  top: -40px;
  left: 300px;
  z-index: 11;
}

.top-right {
  position: absolute;
  top: -32px;
  right: -110px;
}

.top-middle-left-arrow {
  position: absolute;
  top: 10%;
  left: -25px;
  z-index: 11;
}

.middle-left {
  position: absolute;
  top: 38%;
  left: -150px;
}

.top-middle-right-arrow {
  position: absolute;
  top: 10%;
  right: -30px;
  z-index: 11;
}

.middle-right {
  position: absolute;
  top: 38%;
  right: -190px;
}

.bottom-middle-left-arrow {
  position: absolute;
  top: 56%;
  left: -25px;
  z-index: 11;
}

.bottom-left {
  position: absolute;
  bottom: -30px;
  left: -80px;
}

.bottom-center {
  position: absolute;
  bottom: -50px;
  left: 260px;
}

.bottom-left-center-arrow {
  position: absolute;
  bottom: -30px;
  left: 185px;
  z-index: 11;
}

.bottom-center-right-arrow {
  position: absolute;
  bottom: -30px;
  right: 150px;
  z-index: 11;
}

.bottom-middle-right-arrow {
  position: absolute;
  bottom: 56px;
  right: -30px;
  z-index: 11;
}

.bottom-right {
  position: absolute;
  bottom: -30px;
  right: -60px;
}

.last-betting-round-details {
  position: absolute;
  top: 28%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
}

.last-betting-text {
  animation-name: lastround;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  font-size: larger;
}

@keyframes lastround {
  /* from { color: rgb(226, 198, 35); } */
  from {
    color: rgb(133, 221, 0);
  }
  to {
    color: rgb(255, 30, 0);
  }
}

.centered {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
}

.centeredironcross {
  position: absolute;
  top: 28%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
}

.winningdetails-homerun {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
  text-align: center;
  color: gold;
}

.rank-center {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
  text-align: center;
  color: gold;
  font-size: 17px;
}

.playernames {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
  width: fit-content;
}

.doyainfo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
  width: fit-content;
}

.doCards:hover {
  cursor: pointer;
}

.doYaBtn {
  width: fit-content;
  height: 30px;
  background: #050402 0% 0% no-repeat padding-box;
  border: 1px solid #d9bd5a;
  border-radius: 5px;
  opacity: 1;
  font: normal normal bold;
  letter-spacing: 0px;
  color: #d9bd5a;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 4px !important;
}

.doYaBtn:hover,
.doYaBtn:active {
  border-color: #032b13 !important;
  background: #d9bd5a !important;
  box-shadow: none !important;
  color: #050402 !important;
}

.center-top {
  position: absolute;
  top: -12%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.actionMsgblink {
  animation-name: lastround;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  font-size: larger;
}

.KickCard {
  position: absolute;
  height: auto;
  width: 90%;
  background-color: black;
  border: 1px solid gold;
  border-radius: 0.3rem;
  box-shadow: 0 2px 8px rgb(30 37 43 / 30%);
  top: 80px;
  left: 22px;
  z-index: 15;
}

.paymentCard {
  position: absolute;
  margin: 0 !important;
  padding: 5px !important;
  height: 30px !important;
  min-width: 150px !important;
  border: none !important;
  border-radius: 5px;
  z-index: 11;
  top: 80px;
  left: 45px;
  background: #10101b !important;
}

.closePayBtn {
  position: absolute;
  left: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #10101b !important;
  width: 30px !important;
  height: 30px;
  color: white;
  border-radius: 5px;
}

.closePayBtn:hover {
  cursor: pointer;
  color: red;
}

.closeKickBtn:hover {
  cursor: pointer;
  color: red;
}

.payAmountArea {
  height: 20px;
  width: 80px;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 0px;
  margin-right: 8px;
}

.payButton {
  margin: 0 !important;
  padding-top: 1.5px;
  height: 20px !important;
  min-width: 40px !important;
  border: none !important;
  border-radius: 5px;
  z-index: 11;
  background: red;
  font-size: 12px;
}

.kickButton {
  /* margin: 0 !important;     */
  margin-bottom: 10px;
  padding-top: 1.5px;
  padding-bottom: 1.5px;
  /* height: 20px !important; */
  height: auto;
  min-width: 40px !important;
  border-radius: 5px;
  z-index: 11;
  background: #050402 0% 0% no-repeat padding-box;
  border: 1px solid #d9bd5a;
  font-size: 12px;
  color: #d9bd5a;
}

.kickButton:hover {
  background: #d9bd5a 0% 0% no-repeat padding-box;
  color: black;
  border: 1px solid black;
}

.playerCard {
  margin: 0 !important;
  /*padding: 0 !important;*/
  background: #365d45 !important;
  height: 80px !important;
  min-width: 200px !important;
  border: none !important;
  border-radius: 10px;
}

.opacitycontroller {
  opacity: 0.5; /* Opacity for Modern Browsers */
  filter: alpha(opacity=50); /* Opacity for IE8 and lower */
  zoom: 1; /* Fix for IE7 */
}

.kicknewBtn {
  z-index: 14;
  position: absolute;
  bottom: -30px;
  left: 40%;
  max-width: auto;
  height: 30px;
  background: #050402 0% 0% no-repeat padding-box;
  border: 1px solid #d9bd5a;
  border-radius: 5px;
  opacity: 1;
  font: normal normal bold;
  letter-spacing: 0px;
  color: #d9bd5a;
  font-size: 14px;
  font-weight: bold;
  padding-top: 4px !important;
}

.redcolor {
  color: red !important;
}

.redcolor:hover {
  color: black !important;
}

.kicknewBtn:hover {
  padding-top: 2px !important;
  cursor: pointer;
  border: 2px solid #050402;
  background: #d9bd5a 0% 0% no-repeat padding-box;
  color: #050402;
  z-index: 14;
}

.currentPlayerCard {
  margin: 0 !important;
  /*padding: 0 !important;*/
  background: #365d45 !important;
  height: 80px !important;
  min-width: 200px !important;
  border-width: 2px !important;
  border-radius: 10px;
  border-color: gold !important;
}

.potCard {
  border-radius: 10px;
  height: 70px !important;
  width: 100px !important;
}

.centerGame {
  margin: 0 !important;
  padding: 0 !important;
  background: #365d45 !important;
  height: 80px !important;
  min-width: 60px !important;
  border: none !important;
  border-radius: 10px;
}

.centerGame:hover {
  cursor: pointer;
}

.gameCard {
  border-radius: 10px;
  height: 70px !important;
  width: 60px !important;
}

.pot-show {
  font-size: 12px;
  position: absolute;
  top: 5%;
  left: 38%;
  bottom: 160px;
  width: fit-content;
  color: whitesmoke;
  max-width: 500px;
}

.centereddetails {
  position: absolute;
  top: 58%;
  left: 46%;
  transform: translate(-50%, -50%);
}

.coin-topleft {
  cursor: pointer;
  position: absolute;
  left: 210px;
  bottom: -20px;
  z-index: 1;
}

.coin-topright {
  cursor: pointer;
  position: absolute;
  left: -35px;
  top: 65px;
  z-index: 1;
}

.coin-midleft {
  cursor: pointer;
  position: absolute;
  left: 220px;
  bottom: 10px;
  z-index: 1;
}

.coin-midright {
  cursor: pointer;
  position: absolute;
  left: -35px;
  bottom: -5px;
  z-index: 1;
}

.coin-botleft {
  cursor: pointer;
  position: absolute;
  left: 200px;
  bottom: 70px;
  z-index: 1;
}

.coin-botcenter {
  cursor: pointer;
  position: absolute;
  left: 95px;
  bottom: 70px;
  z-index: 1;
}

.coin-botright {
  cursor: pointer;
  position: absolute;
  left: -15px;
  bottom: 50px;
  z-index: 1;
}

.coinvalue-topleft {
  cursor: pointer;
  position: absolute;
  left: 231px;
  bottom: 4px;
  z-index: 1;
  color: black;
}

.coinvalue-topright {
  cursor: pointer;
  position: absolute;
  left: -12px;
  top: 82px;
  z-index: 1;
  color: black;
}

.coinvalue-midleft {
  cursor: pointer;
  position: absolute;
  left: 243px;
  bottom: 33px;
  z-index: 1;
  color: black;
}

.coinvalue-midright {
  cursor: pointer;
  position: absolute;
  left: -12px;
  bottom: 19px;
  z-index: 1;
  color: black;
}

.coinvalue-botleft {
  cursor: pointer;
  position: absolute;
  left: 223px;
  bottom: 93px;
  z-index: 1;
  color: black;
}

.coinvalue-botcenter {
  cursor: pointer;
  position: absolute;
  left: 117px;
  bottom: 94px;
  z-index: 1;
  color: black;
}

.coinvalue-botright {
  cursor: pointer;
  position: absolute;
  left: 7px;
  bottom: 73px;
  z-index: 1;
  color: black;
}

.legs-text {
  position: absolute;
  left: 75px;
  bottom: 75px;
  z-index: 1;
}

.legs-number {
  position: absolute;
  left: 155px;
  bottom: 75px;
  z-index: 1;
}

.cards-display {
  position: absolute;
  left: 45px;
  bottom: -60px;
  z-index: 1;
}

.winningtype-topleft {
  cursor: pointer;
  position: absolute;
  left: 228px;
  top: 0px;
  z-index: 1;
  margin: 0 !important;
  padding-top: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: fit-content !important;
  width: fit-content !important;
}

.winningtype-topright {
  cursor: pointer;
  position: absolute;
  left: -18px;
  top: 0px;
  z-index: 1;
  margin: 0 !important;
  padding-top: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: fit-content !important;
  width: fit-content !important;
}

.winningtype-midleft {
  cursor: pointer;
  position: absolute;
  left: 228px;
  bottom: 25px;
  z-index: 1;
  margin: 0 !important;
  padding-top: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: fit-content !important;
  width: fit-content !important;
}

.winningtype-midright {
  cursor: pointer;
  position: absolute;
  left: -22px;
  bottom: 0px;
  z-index: 1;
  margin: 0 !important;
  padding-top: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: fit-content !important;
  width: fit-content !important;
}

.winningtype-botleft {
  cursor: pointer;
  position: absolute;
  left: 180px;
  bottom: 80px;
  z-index: 1;
  margin: 0 !important;
  padding-top: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: fit-content !important;
  width: fit-content !important;
}

.winningtype-botcenter {
  cursor: pointer;
  position: absolute;
  left: 30px;
  bottom: 80px;
  z-index: 1;
  margin: 0 !important;
  padding-top: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: fit-content !important;
  width: fit-content !important;
}

.winningtype-botright {
  cursor: pointer;
  position: absolute;
  left: 30px;
  bottom: 80px;
  z-index: 1;
  margin: 0 !important;
  padding-top: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: fit-content !important;
  width: fit-content !important;
}

.type-High {
  background: transparent;
  color: rgb(255, 255, 255);
  border: 3px solid blue;
  border-radius: 5px;
}

.type-Low {
  background: transparent;
  color: rgb(255, 255, 255);
  border: 3px solid greenyellow;
  border-radius: 5px;
}

.type-Mixed {
  background: transparent;
  color: rgb(255, 255, 255);
  border: 3px solid purple;
  border-radius: 5px;
}

.fold-display {
  position: absolute;
  left: 150px;
  bottom: -40px;
  z-index: 1;
}

.fold-display-fivecd {
  position: absolute;
  left: 150px;
  bottom: -55px;
  z-index: 1;
}

.frankensteinHand {
  font-size: large;
  position: absolute;
  left: 40%;
  top: -10%;
  /* color: black; */
  /* animation: highlightanimate 0.8s ease-in-out infinite alternate; */
}

.highlightedplayer {
  color: black;
  animation: highlightanimate 0.8s ease-in-out infinite alternate;
}

@keyframes highlightanimate {
  from {
    text-shadow: 0 0 5px #fff, 0 0 3px #fff, 0 0 3px rgb(221, 188, 0),
      0 0 3px rgb(221, 188, 0), 0 0 3px rgb(221, 188, 0),
      0 0 3px rgb(221, 188, 0), 0 0 3px rgb(221, 188, 0);
  }

  to {
    text-shadow: 0 0 10px #fff, 0 0 7px rgb(253, 222, 46),
      0 0 7px rgb(253, 222, 46), 0 0 7px rgb(253, 222, 46),
      0 0 7px rgb(253, 222, 46), 0 0 7px rgb(253, 222, 46),
      0 0 7px rgb(253, 222, 46);
  }
}

.vl {
  border-left: 2px solid white;
  height: 250px;
  position: absolute;
  top: 0;
}

.winning-details {
  position: absolute;
  left: 165px;
  top: 8px;
  font-size: 12px;
}

.cards-display {
  position: absolute;
  left: 45px;
  bottom: -60px;
  z-index: 1;
}

.downcards-display {
  position: absolute;
  right: 25px;
  bottom: -60px;
  z-index: 1;
}

.downcards-display-727 {
  position: absolute;
  left: 100px;
  bottom: -60px;
  z-index: 1;
}

.rolloncards-display {
  position: absolute;
  left: 95px;
  bottom: -60px;
  z-index: 1;
}

.playerdragon {
  position: absolute;
  left: 230px;
  bottom: -7px;
  z-index: 1;
}

.frontsettings-display {
  position: absolute;
  left: 230px;
  bottom: -7px;
  z-index: 1;
}

.backsettings-display {
  position: absolute;
  left: 210px;
  bottom: -75px;
  z-index: 1;
}

.middlesettings-display {
  position: absolute;
  left: 210px;
  bottom: -40px;
  z-index: 1;
}

.displaycards357 {
  position: absolute;
  left: 45px;
  bottom: -60px;
  z-index: 1;
}

.hold {
  background: transparent;
  color: rgb(255, 255, 255);
  border: 3px solid blue;
  border-radius: 5px;
}

.drop {
  background: transparent;
  color: rgb(255, 255, 255);
  border: 3px solid purple;
  border-radius: 5px;
}

.selectiontype-botcenter {
  cursor: pointer;
  position: absolute;
  left: 175px;
  bottom: 80px;
  z-index: 1;
  margin: 0 !important;
  padding-top: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: fit-content !important;
  width: fit-content !important;
}

.selectiontype-botright {
  cursor: pointer;
  position: absolute;
  left: 170px;
  bottom: 80px;
  z-index: 1;
  margin: 0 !important;
  padding-top: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: fit-content !important;
  width: fit-content !important;
}

.details-357 {
  position: absolute;
  left: 165px;
  top: 8px;
}

.details-eligible {
  position: absolute;
  left: 0px;
  top: 18px;
}

.details-pot {
  position: relative;
  left: 170px;
  top: 18px;
}

.details-wpw {
  position: relative;
  left: -20px;
  top: 0px;
  font-size: 12.5px;
}

.details-sidebet {
  position: relative;
  left: -20px;
  top: 5px;
  font-size: 12.5px;
}

.details-sidebet-center {
  position: absolute;
  left: 0px;
  top: 25px;
  font-size: 12.5px;
}

.ready-display {
  position: absolute;
  left: 100px;
  bottom: 90px;
  z-index: 1;
  color: greenyellow;
  font-size: small;
}

.passes-display {
  position: absolute;
  left: 30px;
  bottom: -75px;
  z-index: 1;
  /* color: greenyellow; */
  font-size: small;
}

.community-cards {
  position: absolute;
  left: 165px;
  top: 220px;
  z-index: 1;
  color: black;
}

.doya-cards {
  position: absolute;
  left: 37%;
  top: 250px;
  z-index: 1;
}

.baseballinfo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
  width: fit-content;
}

.baseball-actions {
  position: absolute;
  left: 45%;
  transform: translate(-50%, -50%);
  top: 230px;
  z-index: 1;
}

.monsterhand-cards {
  position: absolute;
  left: 165px;
  top: 250px;
  z-index: 1;
}

.discarded-monster-cards {
  position: absolute;
  left: 180px;
  top: 195px;
  z-index: 1;
}

.monster-hand-description {
  position: absolute;
  left: 25%;
  top: 330px;
  z-index: 1;
}

.card-exchange-display {
  font-size: small;
  position: absolute;
  left: 68px;
  bottom: -20px;
  z-index: 1;
}

.card-exchange-display-left {
  font-size: small;
  position: absolute;
  left: 20px;
  bottom: -20px;
  z-index: 1;
}

.card-exchange-display-right {
  font-size: small;
  position: absolute;
  left: 150px;
  bottom: -20px;
  z-index: 1;
}

.homerunhand-description-front {
  min-width: 170px;
  font-size: small;
  position: absolute;
  left: -200px;
  bottom: -11px;
  z-index: 1;
}

.homerunhand-description-middle {
  min-width: 170px;
  font-size: small;
  position: absolute;
  left: -180px;
  bottom: -0.5px;
  z-index: 1;
}

.homerunhand-description-back {
  min-width: 170px;
  font-size: small;
  position: absolute;
  left: -180px;
  bottom: 12px;
  z-index: 1;
}

.hand-description {
  min-width: 300px;
  font-size: 11px;
  position: absolute;
  left: -20px;
  bottom: -14px;
  z-index: 1;
  color: gold;
}

.opener-display {
  height: 50px;
  position: absolute;
  left: 140px;
  bottom: 10px;
  z-index: 1;
}

.dealer5cs-display {
  height: 50px;
  position: absolute;
  top: -190px;
  left: -40px;
  z-index: 1;
}

.sb-display {
  height: 50px;
  position: absolute;
  left: -20px;
  bottom: 10px;
  z-index: 1;
}

#homeruntable {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#homeruntable td {
  border: 1px solid black;
  padding: 2px;
  min-width: 50px;
  color: white;
  font-weight: normal;
}

#homeruntable th {
  color: white;
  border: 1px solid black;
  text-align: center;
  padding: 2px;
  min-width: 155px;
  color: white;
  font-weight: normal;
}

.dragon {
  position: absolute;
  /*top: 28%;*/
  /*left: 55%;*/
  height: 10px;
  width: 5vw;
  transform: translate(-50%, -50%);
  justify-content: space-between;
}

.bonusdetails-homerun {
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
}

.playerPay:hover {
  cursor: pointer;
}

.dragon-image {
  position: absolute;
  /*width: 120px;*/
  width: 6vw;
  height: auto;
  left: 25px;
  /*top:14vh*/
  top: 145px;
}

.arrow-bottom-left {
  position: absolute;
  left: 90px;
  bottom: -25px;
}

.arrow-left-up {
  position: absolute;
  bottom: 20px;
  left: -25px;
  transform: rotate(90deg);
}

.arrow-right-down {
  position: absolute;
  bottom: 20px;
  right: -45px;
  transform: rotate(270deg);
}

.arrow-top-right {
  position: absolute;
  top: -25px;
  left: 90px;
  transform: rotate(180deg);
}

.cin-topleft-left {
  position: absolute;
  top: -20px;
  left: 25px;
  transform: rotate(180deg);
}

.cin-topleft-right {
  position: absolute;
  top: 10px;
  left: -25px;
  transform: rotate(270deg);
}

.cin-topright-left {
  position: absolute;
  bottom: 45px;
  right: -45px;
  transform: rotate(270deg);
}

.cin-topright-right {
  position: absolute;
  top: -20px;
  left: 170px;
}

.cin-midleft-left {
  position: absolute;
  top: 20px;
  left: -25px;
  transform: rotate(90deg);
}

.cin-midleft-right {
  position: absolute;
  top: 40px;
  left: -27px;
  transform: rotate(270deg);
}

.cin-midright-left {
  position: absolute;
  bottom: 15px;
  left: 210px;
  transform: rotate(270deg);
}

.cin-midright-right {
  position: absolute;
  top: 20px;
  left: 212px;
  transform: rotate(90deg);
}

.cin-botleft-left {
  position: absolute;
  top: 45px;
  left: -22px;
  transform: rotate(90deg);
}

.cin-botleft-right {
  position: absolute;
  bottom: -25px;
  left: 25px;
  transform: rotate(180deg);
}

.cin-botcenter-left {
  position: absolute;
  bottom: -25px;
  left: 30px;
}

.cin-botcenter-right {
  position: absolute;
  bottom: -26px;
  right: 0px;
  transform: rotate(180deg);
}

.cin-botright-left {
  position: absolute;
  bottom: -25px;
  right: 0px;
}

.cin-botright-right {
  position: absolute;
  bottom: 15px;
  right: -42px;
  transform: rotate(90deg);
}

.removetext {
  color: red;
  font-weight: bold;
}

.removetext:hover {
  cursor: pointer;
}

.removePlayerSheet {
  position: absolute;
  z-index: 10;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: black;
  border: 1px solid gold;
  border-radius: 0.3rem;
  box-shadow: 0 2px 8px rgb(30 37 43 / 30%);
  height: fit-content;
  width: fit-content;
  padding: 5px;
  color: gold;
}

.removeBtn {
  max-width: 80px;
  height: 30px;
  background: #050402 0% 0% no-repeat padding-box;
  border: 1px solid #d9bd5a;
  border-radius: 5px;
  opacity: 1;
  font: normal normal bold;
  letter-spacing: 0px;
  color: #d9bd5a;
  font-size: 14px;
  font-weight: bold;
  padding-top: 4px !important;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}

.removeBtn:hover {
  padding-top: 2px !important;
  cursor: pointer;
  border: 2px solid #050402;
  background: #d9bd5a 0% 0% no-repeat padding-box;
  color: #050402;
  z-index: 14;
}

.cardanimation {
  animation-name: cardanimationui;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}

@keyframes cardanimationui {
  0% {
    opacity: 0;
    transform: rotateY(90deg);
  }
  100% {
    opacity: 1;
  }
}

.textchange {
  border: gold 2px solid;
  padding: 2px;
  border-radius: 2px;
}

.onhover:hover {
  cursor: pointer;
}

.winnerWildCards {
  position: relative;
}

.isWildWinnerCard {
  position: absolute;
  bottom: -5px;
  left: -10px;
  animation-name: color_changewinner;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.fa-winner {
  transform: scale(0.65, 0.65);
}

@keyframes color_changewinner {
  /* from { color: rgb(226, 198, 35); } */
  from {
    color: rgb(133, 221, 0);
  }
  to {
    color: blue;
  }
}

.normalcommunitycard {
  position: relative;
}

.isWildCardCommunity {
  position: absolute;
  top: 60px;
  left: 5px;
  animation-name: color_change_community;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.isWildCardCommunityIron {
  position: absolute;
  top: 40px;
  left: 2px;
  animation-name: color_change_community;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes color_change_community {
  /* from { color: rgb(226, 198, 35); } */
  from {
    color: rgb(133, 221, 0);
  }
  to {
    color: blue;
  }
}

.ironcardzero {
  position: absolute;
  top: -10px;
  left: 90px;
}

.ironcardtwo {
  position: absolute;
  left: 200px;
  top: -10px;
}

.ironcardone {
  position: absolute;
  left: 145px;
  top: -75px;
}

.ironcardthree {
  position: absolute;
  left: 145px;
  top: 55px;
}

.ironcardfour {
  position: absolute;
  left: 145px;
  top: -10px;
}

.winnergif {
  position: absolute;
  z-index: 11;
  left: -90px;
  top: -60px;
  pointer-events: none;
}
