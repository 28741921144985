.PlayerToast {
    z-index: 10;
    width: 180px;
}

.PlayerToast .toast-header {
    background: #D9BD5A;
    color: #050402;
}

.dealerImg {
    position: absolute;
    top: -15px;
    left: 20%;
    z-index: 1;
}

.starImg {
    position: absolute;
    top: -10px;
    left: 180px;
    font-size: 20px;
    z-index: 1;
    color: #D9BD5A;
}

.historyImg {
    cursor: pointer;
    position: relative;
    left: 14px;
    z-index: 1;
}

.HistoryModalHeader {
    font-size: 20px;
}

.HistoryModalBody {
    font-size: 14px;
}

.profileImg {
    position: relative;
    z-index: 1;
    border-radius: 50%;
    height: 50px;
    width: 50px;
}

.resulthr {
    width: 100%;
}

.namehr {
    width: 23%;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    font-weight: bold;
}

.fronthr {
    width: 23%;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.midhr {
    width: 23%;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.backhr {
    width: 23%;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.pointshr {
    width: 8%;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}


.fnt-11px{
    font-size: 0.7rem;
}

.playerKick:hover {
    cursor: pointer;
}
