#root {
  display: flex;
  flex-flow: column wrap;
}

.deck {
  display: flex;
  flex-flow: row wrap;
}

.card {
  display: flex;
  width: 68px;
  height: 80px;
  background-color: #FFF;
  border: 1px solid black;
  margin: 0 4px;
  padding: 4px;
}

.card-black {
  color: black;
}

.card-red {
  color: red;
}

.card-value {
  font-size: 16px;
}

.card-suit {
    font-size: 16px;
}

.card-tl {
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.card-br {
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  justify-content: flex-start;
  transform:rotate(-180deg);
}

.newcardsui {
  border: 0px solid black;
  border-radius: 4px;
}

input[type=button] {
  padding: 8px 16px;
  margin: 16px;
  border-radius: 8px;
  background-color: red;
  border: 1px solid white;
  color: white;
  text-transform: uppercase;
}
