.messageBox {
    background: #F3F3F3;
    border-radius: 20px;
    padding: 5px 20px;
    color: black;
    display: inline-block;
    max-width: 80%;
  }

  .messageText {
    width: 100%;
    letter-spacing: 0;
    float: left;
    font-size: 0.9em;
    word-wrap: break-word;
    margin-bottom: 0;
    cursor: pointer;
  }

  .messageText img {
    vertical-align: middle;
  }

  .messageContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 5%;
    margin-top: 3px;
    font-size: 14px;
  }

  .sentText {
    display: flex;
    align-items: center;
    font-family: Helvetica;
    color: black;
    letter-spacing: 0.3px;
  }

  .pl-10 {
    padding-left: 10px;
  }

  .pr-10 {
    padding-right: 10px;
  }

  .justifyStart {
    justify-content: flex-start;
  }

  .justifyEnd {
    justify-content: flex-end;
  }

  .colorWhite {
    color: black;
  }

  .colorDark {
    color: #353535;
  }

  .backgroundBlue {
    background: #f0e58ac7;
  }

  .backgroundLight {
    background: #F3F3F3;
  }
