html, body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.signIn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.createjoin {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
}

.homeLogo {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.homeImg {
  height: 100vh;
  width: 100vw;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: white;
}

.joinInnerContainer {
  width: 20%;
}

.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
}

.heading {
  color: goldenrod;
  font-size: 10px;
  align-items: center;
}

.topbar {
  text-align: center;
  border-bottom: 2px solid white;
}

.button {
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #2979FF;
    padding: 20px;
    border-radius: 5px;
    display: inline-block;
    border: none;
    width: 100%;
}

.infobutton {
    background-color: Transparent !important;
    background-repeat:no-repeat;
    border-color: goldenrod;
    color: goldenrod;
    border-width: 5px;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    margin-top: 10px;
}

.infobutton:hover {
  font-weight: bold;
}

.topinfo {
  text-align: center;
}

.sign-out {
  background: red !important;
  margin-top: 10px;
}

.sign-out:hover {
  font-weight: bold;
}

.mt-20 {
  margin-top: 10px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 90%;
  }


}

button:focus {
  outline: 0;
}


#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
border: 1px solid #ddd;
padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
padding-top: 12px;
padding-bottom: 12px;
text-align: left;
background-color: #4CAF50;
color: white;
}
