@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import "../../node_modules/bootstrap/scss/bootstrap";
@import '../../node_modules/font-awesome/css/font-awesome.min.css';

.row {
  margin: 0;
}

.btn:focus {
  border-color: white;
  background: white;
  color: #050402;
  outline: none;
  box-shadow: none;
}

textarea:focus {
  outline: none;
}
