#root {
  display: flex;
  flex-flow: column wrap;
}

.winner-deck {
  display: flex;
  flex-flow: row wrap;
}

.winner-card {
  display: flex;
  width: 38px;
  height: 55px;
  background-color: #FFF;
  border: 1px solid black;
  border-radius: 4px;
  margin: 0 -7px;
  padding: 4px;
}

.newwinning {
  display: flex;
  margin: 0 -10px;
  /* border: 1px solid black; */
  border-radius: 4px;
}

.border-active {
  border: 1px solid red;
}

.winner-card-black {
  color: black;
}

.winner-card-red {
  color: red;
}

.winner-card-value {
  font-size: 15px;
}

.winner-card-suit {
    font-size: 20px;
}

.winner-card-tl {
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.winner-card-br {
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  justify-content: flex-start;
  transform:rotate(-180deg);
}

input[type=button] {
  padding: 8px 16px;
  margin: 16px;
  border-radius: 8px;
  background-color: red;
  border: 1px solid white;
  color: white;
  text-transform: uppercase;
}
