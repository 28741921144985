html, body {
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
  }

  #root {
    height: 100vh;
  }

  * {
    box-sizing: border-box;
  }

  .roomdata {
    max-height: 60vh;
    overflow-y: scroll;
  }

  .homeImgjoin {
    height: 100vh;
    width: 100vw;
  }

  .joinRoomInput {
    border-radius: 4px;
    border: 2px solid goldenrod;
    padding: 6px 10px;
    /*width: 70%;*/
    background-color: black;
    background-repeat:no-repeat;
    color: gold;
    outline: none;

  }

.joinRoomInput > input::placeholder{
  color: darkgoldenrod;
  opacity: 70%;
}

.joinRoomInput:focus-within {
  border: 2px solid #da8720;
}

  .joinRoomContainer {
    position: absolute;
    top: 55%; 
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100vh;
    color: goldenrod;
  }

  .joinLogo {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .iconsfirebase{
    position: absolute;
    min-width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .joinOuterContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100vh;
    align-items: center;
    background-color: white;
  }

  .joinRoomInnerContainer {
    width: 100%;
  }

  .joinInput {
    border-radius: 0;
    padding: 15px 20px;
    width: 100%;
  }

  .topbar {
    text-align: center;
    border-bottom: 2px solid white;
  }

  .button {
      color: #fff !important;
      text-transform: uppercase;
      text-decoration: none;
      background: #055021;
      padding: 10px;
      border-radius: 5px;
      display: inline-block;
      border: none;
      width: 100%;
  }

  .mt-20 {
    margin-top: 20px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .joinOuterContainer {
      height: 100%;
    }

    .joinInnerContainer {
      width: 90%;
    }
  }

  button:focus {
    outline: 0;
  }

  .button:hover {
    background-color: #032B13;
  }

  .joinOuterContainer h2 {
      font-size: 20px;
      font-weight: bold;
  }

  .cardBorder {
      border-radius: 4px;
      border: 1px solid goldenrod;
  }
 .w-35{
   width:35%;
 }

 .w-70{
   width:70%;
 }

 .search-input-text-box{
   background-color: black;
   color: gold;
   outline:none;
   border: none;
 }

