.player-account-details {
  /* text-align: center; */
  margin-top: 10px;
}

.center-align-text {
  text-align: center;
}

.align-payment-options {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.paymentinfo {
  color: gray;
}

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
  cursor: pointer;
}

#customers tr:hover {
  background-color: #ddd;
  cursor: pointer;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}

.DetailsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  min-width: 108px;
  height: 30px;
  color: #050402;
  border-color: white;
  font-size: 14px;
  font-weight: bold;
  margin-left: 46.45%;
}

.DetailsBtn:hover,
.DetailsBtn:active {
  border-color: #032b13 !important;
  background: #d9bd5a !important;
  box-shadow: none !important;
  color: #050402 !important;
}

.detailstable {
  color: darkblue;
  font-size: medium;
}

.mymodal-sheet {
  width: 90% !important;
}

.homepageaccountlink {
  position: absolute;
  left: 40px;
  top: 40px;
}

.homepageaccountBtn {
  font-size: 20px;
  color: #014c96;
  border: 2px solid #014c96 !important;
  border-radius: 4px !important;
  padding: 5px;
  border-radius: 2px;
}

.homepageaccountBtn:hover {
  cursor: pointer;
  font-weight: bold;
  color: #014c96 !important;
}

@media only screen and (max-width: 580px) {
  .homepageaccountlink {
    display: none;
  }
}
