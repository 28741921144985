.newHomeImg {
  height: 100vh;
  width: 100vw;
  margin-top: 8vh;
}

.homecarou1 {
  /* height: 100% !important; */
  background-image: url("../assets/HomeBG.png");
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.containertwo {
  width: 100vw;
  height: fit-content;
  /* margin-top: 3.5vh; */
  background: transparent
    radial-gradient(
      closest-side at 50% 50%,
      #619ecf 0%,
      #4c80ac 8%,
      #366084 18%,
      #234464 29%,
      #152f4b 40%,
      #0b2039 52%,
      #04172e 64%,
      #03152b 78%,
      #020f26 88%,
      #00001b 100%
    )
    0% 0% no-repeat padding-box;
}

.titleinfo {
  padding-top: 10.24vh;
  color: var(--unnamed-color-d9bd5a);
  text-align: center;
  font: normal normal bold 66px/87px Roboto Slab;
  letter-spacing: 0px;
  color: #d9bd5a;
  opacity: 1;
  position: relative;
}

.gamesinfo {
  padding-top: 5.26vh;
}

.container {
  padding-top: 20px;
  padding-left: 13.28vw;
  padding-right: 13.28vw !important;
  padding-right: 0px;
  min-width: 100vw;
  display: flex;
  justify-content: space-between;
}

.lastcontainer {
  padding-top: 20px;
  padding-left: 33.21vw;
  padding-right: 33.21vw !important;
  padding-right: 0px;
  min-width: 100vw;
  display: flex;
  justify-content: space-between;
}

.item {
  padding: 10px;
  background-color: rgba(111, 41, 97, 0.3);
  border: 2px solid rgba(111, 41, 97, 0.5);
}

.iconImg {
  width: 12.42vw;
  height: 12.42vw;
  min-width: 100px;
  min-height: 100px;
  margin-bottom: 5px;
}

.iconImg:hover {
  cursor: pointer;
}

.iconText {
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bolder Roboto Slab max(1.5vh, 20px);
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.iconText:hover {
  cursor: pointer;
}

.homeLogo {
  position: absolute;
  top: 17%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 15vh;
  width: 15vw;
}

.homeLogoLargerNew {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 45vw !important;
}

.homeLogoLarger {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 45vw !important;
}

.carouImg1 {
  height: 18vh !important;
  width: max-content;
  width: 16vw !important;
  margin-bottom: 65px;
}

.carouImg2 {
  height: 27vh !important;
  width: max-content;
  width: 24vw !important;
  /* margin-bottom: 65px; */
}

.carou2 {
  margin-top: 50px;
  color: goldenrod;
}

.carouheading1 {
  font: normal normal bold 40px/48px Roboto Slab;
}

.carouheading2 {
  font: normal normal 30px/38px Roboto Slab;
  color: whitesmoke !important;
}

.loginscreen {
  position: absolute;
  top: 37vh;
  left: 50%;
  transform: translate(-50%, -50%);
}

.userscreen {
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
}

.userinfo {
  color: goldenrod;
  align-content: center;
  text-align: center;
}

.homebuttons {
  align-content: center;
  text-align: center;
}

.createjoinbutton {
  display: flex;
  justify-content: space-between;
  padding-right: 1.7vw;
}

.choiceBtn {
  background-color: black !important;
  background-repeat: no-repeat;
  border-color: goldenrod;
  color: goldenrod;
  border-width: 5px;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  width: 150%;
  margin-top: 15px;
}

.choiceBtn:hover {
  cursor: pointer;
  font-weight: bold;
}

.myaccountBtn {
  background-color: black !important;
  background-repeat: no-repeat;
  border-color: goldenrod;
  color: goldenrod;
  border-width: 5px;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  margin-top: 15px;
}

.myaccountBtn:hover {
  cursor: pointer;
  font-weight: bold;
}

.trueBtn {
  position: absolute;
  top: 75vh;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ad-classhomelogin {
  position: absolute;
  margin-top: 50px;
  margin-left: -125px;
}

.ad-classhomelogout {
  position: absolute;
  margin-left: -400px;
  margin-top: 20px;
}

.ad-classhometwo {
  position: absolute;
  margin-top: 60px;
}

.ad-classhomethree {
  position: absolute;
  margin-top: 30px;
}

.ad-classhomefour {
  position: absolute;
  top: 100px;
  left: 50px;
}

.ad-classhomefive {
  position: absolute;
  top: 100px;
  right: 50px;
}

.mainBtn {
  border: 1px solid var(--unnamed-color-d9bd5a);
  background: #050402 0% 0% no-repeat padding-box;
  border: 1px solid #d9bd5a;
  color: goldenrod;
  border-radius: 10px;
  opacity: 1;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.mainBtnNew {
  border: 1px solid var(--unnamed-color-d9bd5a);
  background: #050402 0% 0% no-repeat padding-box;
  border: 1px solid #d9bd5a;
  color: goldenrod;
  border-radius: 10px;
  opacity: 1;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.mainBtnNew:hover {
  font-weight: bold;
  cursor: pointer;
}

.mainBtn:hover {
  font-weight: bold;
  cursor: pointer;
}

.Navbar {
  position: fixed;
  width: 100vw;
  height: 8vh;
  background: #070707 0% 0% no-repeat padding-box;
  font: normal normal bold 17px/28px Roboto Slab;
  opacity: 1;
  z-index: 5;
  display: flex;
  /* justify-content: space-between;     */
  align-items: center;
}

.Navbar .leftSide {
  flex: 20%;
  height: 8vh;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 2vw;
  color: #d9bd5a;
  font: normal normal bold 17px/28px Roboto Slab;
}

.Navbar .leftSide .linkButton {
  margin-left: 20px;
}

.Navbar .rightSide {
  flex: 80%;
  height: 8vh;
  margin-bottom: -3rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center !important;
  margin-right: 3vw;
}

.Navbar .leftSide .linkButton {
  display: none;
  color: #d9bd5a;
}

.Navbar .rightSide .linkButton {
  display: none;
  color: #d9bd5a;
  margin-bottom: 3rem;
  margin-right: 1vh;
}

.Navbar .rightSide .links {
  height: 8vh;
}

.Navbar .rightSide #hidden {
  display: flex;
}

.Navbar .rightSide span {
  display: flex;
}

.Navbar .rightSide .links a {
  margin-right: 20px;
  text-decoration: none;
  color: #d9bd5a;
  font: normal normal bold 17px/28px Roboto Slab;
}

.Navbar .rightSide .links a:hover {
  cursor: pointer;
}

@media only screen and (max-width: 880px) {
  .Navbar .rightSide .links a {
    font: normal normal bold 13px/24px Roboto Slab;
    margin-right: 16px;
  }

  .myaccountBtn {
    border-width: 4px;
    padding: 8px;
    border-radius: 4px;
    width: 90%;
    margin-top: 12px;
    font-size: 12px;
  }

  .homeLogoLarger {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 70vw !important;
  }
}
/* 
@media only screen and (max-width: 720px) {
  .Navbar .rightSide .links a {
    font: normal normal bold 9px/20px Roboto Slab;
    margin-right: 12px;
  }
} */

.containerone {
  margin-top: inherit;
}

@media only screen and (max-height: 680px) {
  .Navbar .rightSide {
    margin-bottom: 0rem;
  }

  .Navbar .rightSide .linkButton {
    margin-bottom: 0rem;
  }
}

@media only screen and (max-width: 630px) {
  .Navbar .leftSide .linkButton {
    display: flex;
  }

  .Navbar .rightSide .linkButton {
    display: flex;
  }

  .Navbar .rightSide .links a,
  button {
    display: none;
  }

  .Navbar .rightSide #hidden {
    position: absolute;
    left: 0px;
    top: 8vh;
    height: 100vh;
    width: 100%;
    background-color: #070707;
    backdrop-filter: blur(8px);
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-left: 10px;
    animation-name: linkui;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    justify-content: center;
    align-items: center;
  }

  .Navbar .rightSide #hidden a,
  button {
    display: flex;
    margin: 5px;
    font-size: 20px;
    /* border-bottom: 1px solid #d9bd5a; */
    width: fit-content;
  }

  .carouImg1 {
    height: 18vh !important;
    width: max-content;
    width: 26vw !important;
    margin-bottom: 85px;
  }

  .carouImg2 {
    height: 25vh !important;
    width: 45vw !important;
    margin-bottom: 35px;
  }
}

@keyframes linkui {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.headercontainer {
  position: fixed;
  width: 100vw;
  height: 8vh;
  background: #070707 0% 0% no-repeat padding-box;
  opacity: 1;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 4vw;
  padding-right: 4vw;
}

.innerheadercontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.innerheaderitem {
  padding-right: 0.8vw;
  padding-left: 0.8vw;
}

.headertext {
  color: var(--unnamed-color-d9bd5a);
  text-align: left;
  font: normal normal bold 17px/28px Roboto Slab;
  letter-spacing: 0px;
  color: #d9bd5a;
  opacity: 1;
  cursor: pointer;
}

.sitename {
  padding-left: 4.8vw;
  padding-right: 35.7vw;
}

.headerBtn {
  border: 1px solid var(--unnamed-color-d9bd5a);
  padding: 5px;
  background: #050402 0% 0% no-repeat padding-box;
  border: 1px solid #d9bd5a;
  border-radius: 5px;
  opacity: 1;
  /* height: 4.2vh; */
  font: normal normal bold 17px/28px Roboto Slab;
  letter-spacing: 0px;
  color: #d9bd5a;
  margin-right: 10px;
}

.signoutnew {
  border: 1px solid var(--unnamed-color-d9bd5a);
  padding: 5px;
  background: #050402 0% 0% no-repeat padding-box;
  border: 1px solid red;
  border-radius: 5px;
  opacity: 1;
  /* height: 4.2vh; */
  font: normal normal bold 17px/28px Roboto Slab;
  letter-spacing: 0px;
  color: red !important;
}

.signoutnew:hover {
  border: 3px solid red;
  padding-left: 8px;
  padding-right: 8px;
}

.headerBtn:hover {
  border: 3px solid #d9bd5a;
  padding-left: 8px;
  padding-right: 8px;
}

.containerthree {
  width: 100vw;
  height: max(50vh, auto);
  background-color: white;
  padding-left: 9.85vw;
  padding-right: 65vw !important;
  padding-top: 12vw;
}

.innercontainerthree {
  background-color: white;
}

.containerthreenew {
  width: 100vw;
  height: max(50vh, auto);
  background-color: white;
  padding-left: 9.85vw;
  padding-right: 9.85vw !important;
  padding-top: 12vw;
}

.innertitle {
  color: var(--unnamed-color-d9bd5a);
  text-align: left;
  font: normal normal bold 66px/87px Roboto Slab;
  letter-spacing: 0px;
  color: #d9bd5a;
  opacity: 1;
}

.faqtitle {
  color: var(--unnamed-color-d9bd5a);
  text-align: left;
  font: normal normal bold 6vh Roboto Slab;
  letter-spacing: 0px;
  color: #d9bd5a;
  opacity: 1;
}

.innertext {
  text-align: left;
  font: normal normal normal 16px/40px Roboto Slab;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.pokerImg {
  opacity: 1;
  width: 35vw;
  height: 30vw;
}

.containerfour {
  width: 100vw;
  height: fit-content;
  background: #070707 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-left: 7.85vw;
  padding-top: 2.71vw;
  padding-right: 7.85vw !important;
  padding-bottom: 2vh !important;
  color: var(--unnamed-color-d9bd5a);
  text-align: left;
  font: normal normal bold 14px/19px Roboto Slab;
  letter-spacing: 0px;
  color: #d9bd5a;
  opacity: 1;
  margin-top: 10vh;
}

.cardsusImg {
  padding-bottom: 25px;
}

.privacypolicytext {
  text-align: right !important;
  padding-left: 61.85vw !important;
  color: #2588eb;
  padding-bottom: 25px;
}

.privacypolicytext:hover {
  cursor: pointer;
}

.skylightmodal {
  text-align: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background: transparent
    radial-gradient(
      closest-side at 50% 50%,
      #619ecf 0%,
      #4c80ac 8%,
      #366084 18%,
      #234464 29%,
      #152f4b 40%,
      #0b2039 52%,
      #04172e 64%,
      #03152b 78%,
      #020f26 88%,
      #00001b 100%
    )
    0% 0% no-repeat padding-box;
}

.modaltitle {
  color: var(--unnamed-color-d9bd5a);
  text-align: center;
  font: normal normal bold 32px/43px Roboto Slab;
  letter-spacing: 0px;
  color: #d9bd5a;
  opacity: 1;
}

.modaltext {
  color: var(--unnamed-color-d9bd5a);
  text-align: left;
  font: normal normal normal 17px/32px Roboto;
  letter-spacing: 0px;
  color: #d9bd5a;
  opacity: 1;
  padding-left: 10vh;
  padding-right: 10vh;
}

.modalclose {
  position: absolute;
  top: 0.6vw;
  left: 97.2vw !important;
}

.modalclose:hover {
  cursor: pointer;
}

.containerfive {
  width: 100vw;
  height: max(75vh, auto + 10vh);
  background-color: white;
  padding-left: 10vw;
  padding-right: 10vw !important;
  padding-top: 3.2vw;
}

.faqinnercontainer {
  color: var(--unnamed-color-d9bd5a);
  font: normal normal bold 66px/87px Roboto Slab;
  letter-spacing: 0px;
  color: #d9bd5a;
  opacity: 1;
}

.faqquestion {
  font: normal normal bold 22px/28px Roboto Slab;
  letter-spacing: 0px;
  color: #00001b;
  opacity: 1;
}

.titledescription {
  color: var(--unnamed-color-d9bd5a);
  text-align: center;
  font: normal normal normal 14px/16px Roboto;
  letter-spacing: 0px;
  color: #d9bd5a;
  opacity: 1;
}

.titledescriptionrules {
  color: var(--unnamed-color-d9bd5a);
  text-align: center;
  font: normal normal normal 14px/16px Roboto;
  letter-spacing: 0px;
  color: red;
  opacity: 1;
}

.loginoptions {
  z-index: 5;
  position: fixed;
  top: 8vh;
  right: 5px !important;
  animation-name: login;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes login {
  0% {
    opacity: 0;
    top: 4vh;
  }
  100% {
    opacity: 1;
    top: 8vh;
  }
}

.guestloginoption {
  position: fixed;
  z-index: 5;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: black;
  border: 1px solid gold;
  border-radius: 0.2rem;
  box-shadow: 0 2px 8px rgb(30 37 43 / 30%);
  height: fit-content;
  width: fit-content;
  right: 5px !important;
  animation-name: guestloginui;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  max-width: 30rem;
  padding: 20px;
  font: normal normal bold 17px/28px Roboto Slab;
  letter-spacing: 0px;
  color: #d9bd5a;
  opacity: 1;
}

@keyframes guestloginui {
  0% {
    opacity: 0;
    top: 40vh;
  }
  100% {
    opacity: 1;
    top: 44vh;
  }
}

.pageinfo {
  position: absolute;
  left: 5vw;
  top: 30vh;
  max-width: 38vw;
  text-align: left;
}

.pageheader {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-32) / 41px var(--unnamed-font-family-objectivity);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal bold 38px/44px Objectivity;
  letter-spacing: 0px;
  color: goldenrod;
  text-transform: uppercase;
  opacity: 1;
  /* position: absolute;
  left: 10vw;
  top: 11vh; */
}

.pagetext {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-32) / 41px var(--unnamed-font-family-objectivity);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 18px/31px Objectivity;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.paymentInput {
  min-height: 4.2vh;
  border-radius: 5px;
  border: 2px solid;
  color: #014c96;
  border-color: #61affe !important;
  padding-left: 5px;
  font-size: 15px;
  margin-bottom: 10px;
}

.paymentsavebtn {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  opacity: 1;
  height: auto;
  min-height: 4.2vh;
  max-height: 5vh;
  font: normal normal bold 17px/28px Roboto Slab;
  letter-spacing: 0px;
  margin-bottom: 20px;
  /* margin-right: 10vw; */
  line-height: 1;
  display: inline;
  color: #014c96;
  border-color: #014c96 !important;
  background-color: transparent;
}

.paymentsavebtn:hover {
  /* border: 3px solid black; */
  cursor: pointer;
  /* background: skyblue 0% 0% no-repeat padding-box; */
  box-shadow: 0 2px 8px rgb(30 37 43 / 30%);
}

.paymenttype {
  display: inline-block;
  min-width: 150px;
  text-align: center;
}

.gameDetails {
  margin: 3rem;
}

.gamelist {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testImg {
  background-image: url("../assets/HomeBG.png");
  height: 106vh;
  background-size: cover; /* or contain depending on what you want */
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: 6vh;
  background-position: -13.5rem;
}

.testimagefriends {
  background-image: url("../assets/HomeFriends.png");
  height: 106vh;
  background-size: cover; /* or contain depending on what you want */
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: 6vh;
}

.testimagebots {
  background-image: url("../assets/HomeBot.png");
  height: 106vh;
  background-size: cover; /* or contain depending on what you want */
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: 6vh;
}

@media only screen and (max-width: 1550px) {
  .testImg {
    background-position: 80%;
  }

  .testimagebots {
    background-position: 47%;
  }
  .testimagefriends {
    background-position: 47%;
  }
}

@media only screen and (max-width: 880px) {
  .container {
    padding-top: 20px;
    padding-left: 11.28vw;
    padding-right: 11.28vw !important;
    padding-right: 0px;
    min-width: 100vw;
    display: flex;
    justify-content: space-between;
  }

  .titleinfo {
    font: normal normal bold 56px/76px Roboto Slab;
  }

  .titledescription {
    font: normal normal normal 12px/13px Roboto;
  }

  .gameDetails {
    margin: 1.5rem;
  }

  .testImg {
    background-position: 80%;
  }
}

@media only screen and (max-width: 720px) {
  .container {
    padding-top: 20px;
    padding-left: 9.28vw;
    padding-right: 9.28vw !important;
    padding-right: 0px;
    min-width: 100vw;
    display: flex;
    justify-content: space-between;
  }

  .titleinfo {
    font: normal normal bold 46px/65px Roboto Slab;
  }

  .titledescription {
    font: normal normal normal 10px/10px Roboto;
  }

  .gameDetails {
    margin: 1rem;
  }

  .innertitle {
    font: normal normal bold 51px/72px Roboto Slab;
  }

  .faqtitle {
    font: normal normal bold 39px/60px Roboto Slab;
  }

  .pageinfo {
    left: 7vw;
    top: 18vh;
  }

  .pageheader {
    font: normal normal bold 28px/32px Objectivity;
  }

  .pagetext {
    font: normal normal 15px/28px Objectivity;
  }

  .testImg {
    background-position: 80%;
  }
}

.containerthreenew img {
  float: right;
  margin: 10px 10px 15px 20px;
}

@media only screen and (max-width: 580px) {
  .innertitle {
    font: normal normal bold 40px/61px Roboto Slab;
  }

  .faqtitle {
    font: normal normal bold 23px/39px Roboto Slab;
  }

  .mainBtn {
    border: 1px solid var(--unnamed-color-d9bd5a);
    border: 1px solid #d9bd5a;
    color: goldenrod;
    border-radius: 10px;
    opacity: 1;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    font: 13px/29px Roboto Slab !important;
  }

  .pageinfo {
    left: 3vw;
    top: 10vh;
  }

  .pageheader {
    font: normal normal bold 20px/24px Objectivity;
  }

  .pagetext {
    font: normal normal 10px/22px Objectivity;
  }

  .homeLogoLarger {
    display: none;
  }

  .homeLogoLargerNew {
    display: none;
  }

  .testImg {
    background-image: url("../assets/MobileHome.png");
    background-position: 0%;
    background-size: cover; /* or contain depending on what you want */
    background-position: center center;
    background-repeat: no-repeat;
  }

  .userscreen {
    position: absolute;
    top: 58vh;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .testimagefriends {
    background-image: url("../assets/MobileFriends.png");
  }

  .testimagebots {
    background-image: url("../assets/MobileBots.png");
  }

  .pageinfo {
    position: absolute;
    left: 5vw;
    top: 10vh;
    max-width: 80vw;
    text-align: left;
  }
}
