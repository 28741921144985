html,
body {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.homeImg {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
}

.create-room {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
}

.table-rules {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
}

.main-container {
  position: absolute;
  top: 47.5%;
  left: 50%;
  bottom: 0px !important;
  transform: translate(-50%, -50%);
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100vh;
}

.private {
  color: gold;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: #1a1a1d;
}

.joinInnerContainer {
  width: 20%;
}

.createRoomInput {
  border-radius: 4px;
  border: 2px solid goldenrod;
  padding: 6px 10px;
  width: 100%;
  background-color: black;
  background-repeat: no-repeat;
  color: gold;
}

.button {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #2979ff;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  width: 100%;
}

.create-button {
  background-color: goldenrod;
  background-repeat: no-repeat;
  border-color: black;
  color: black;
  border-width: 2px;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  width: 10%;
  display: inline-block;
  margin-top: 20px;
}

.preference-button {
  background-color: goldenrod;
  background-repeat: no-repeat;
  border-color: black;
  color: black;
  border-width: 2px;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  width: auto;
  display: inline-block;
  margin-top: 20px;
}

.preference-button:hover {
  font-weight: bold;
  background-color: goldenrod;
  color: black;
  border-color: black;
}

.create-button:hover {
  font-weight: bold;
  background-color: goldenrod;
  color: black;
  border-color: black;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 90%;
  }
}

button:focus {
  outline: 0;
}

.inputTextArea {
  height: 30px;
  width: 70px;
  border-radius: 4px;
  font-size: 14px;
}

#tablecreate {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#tablecreate td,
#tablecreate th {
  border: 1px solid black;
  background-color: #d9bd5a;
  padding: 8px;
  min-width: 170px;
  width: 33%;
}

#tablecreate tr:nth-child(even) {
  background-color: gold;
}

#tablecreate tr:hover {
  background-color: gold;
}

#tablecreate th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}

.no-limit-true {
  color: red;
}

.no-limit-false {
  color: white;
  background-color: #055021;
  border: 1px solid black;
  border-radius: 5%;
}

.no-limit-false:hover {
  cursor: pointer;
  background-color: #030301;
}

.createroomoutercontainer {
  overflow: auto;
  background: url("../../assets/BG@2x.png") no-repeat fixed;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%;
  min-height: 100vh;
}

.createroominnercontainer {
  margin-top: 1vh;
  align-items: center;
  text-align: center;
  overflow: auto;
}

.createRoomnewInput {
  border-radius: 4px;
  border: 2px solid goldenrod;
  padding: 6px 10px;
  background-color: black;
  background-repeat: no-repeat;
  color: gold;
}

.buyinInput {
  border-radius: 4px;
  border: 2px solid goldenrod;
  padding: 6px 10px;
  background-color: black;
  background-repeat: no-repeat;
  color: gold;
  width: 100px;
  margin-left: 10px;
}

.privateborder {
  border-radius: 4px;
  border: 2px solid goldenrod;
  /* padding: 8.5px 10px; */
  background-color: black;
  width: fit-content;
}

.tablecontainer {
  text-align: center;
  margin: auto;
  margin-top: 20px;
  max-width: 70vw;
  overflow-x: auto;
}

.innerspan {
  font-size: 15px;
}

.otherrules {
  min-width: 300px !important;
}

.homepagelink {
  position: absolute;
  left: 40px;
  top: 40px;
}

.homepageBtn {
  font-size: 20px;
  color: goldenrod;
  border: 2px solid goldenrod;
  padding: 5px;
  border-radius: 2px;
}

.homepageBtn:hover {
  cursor: pointer;
  font-weight: bold;
  color: goldenrod !important;
}

.selecttag {
  margin-top: 5px;
  margin-bottom: -25px;
  min-height: 20px;
  flex-grow: 1;
}

.css-2b097c-container {
  margin-left: auto;
  margin-right: auto;
}

.preferenceselection {
  display: flex;
  align-items: center;
}

/* 
.selectui {
  color: palevioletred;
  background-color: black;
} */

.createroomwildcards {
  float: left;
  margin-left: 20%;
  margin-right: 20%;
}

.createroominfo {
  display: none;
}

.joinroominfo {
  display: none;
}

.errornotfound {
  display: inline;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: goldenrod;
  text-align: center;
}

@media only screen and (max-width: 880px) {
  .tablecontainer {
    max-width: 78vw;
  }
}

@media only screen and (max-width: 720px) {
  .tablecontainer {
    max-width: 85vw;
  }
}

@media only screen and (max-width: 580px) {
  .homepagelink {
    display: none;
  }

  .tablecontainer {
    max-width: 95vw;
  }

  .createroomsize {
    display: none;
  }

  .joinroomsize {
    display: none;
  }

  .createroominfo {
    display: inline;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: goldenrod;
    text-align: center;
  }

  .joinroominfo {
    display: inline;
  }

  .createroomoutercontainer {
    overflow: auto;
    background: url("../../assets/MobileHome.png") no-repeat fixed;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    min-height: 100vh;
  }
}

@media only screen and (max-height: 500px) {
  .createroomoutercontainer {
    overflow: auto;
    background: url("../../assets/HomeBG.png") no-repeat fixed;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    min-height: 100vh;
  }

  .createroominfo {
    display: inline;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: goldenrod;
    text-align: center;
  }

  .createroomsize {
    display: none;
  }

  .joinroomsize {
    display: none;
  }

  .joinroominfo {
    display: inline;
  }
}

.rowtest {
  display: flex;
  justify-content: center;
}

.jokercreate {
  border: 1px solid goldenrod;
  border-radius: 5px;
}

.byrankelement:hover {
  cursor: pointer;
}
